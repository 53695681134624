// components/SocialMediaSidebar.js
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'; // Correct import for faShareAlt
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'; // Correct imports for brands

const SocialMediaSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed z-50 bottom-4 right-4 lg:top-1/3 lg:left-0 lg:right-auto lg:bottom-auto">
      {/* Toggle Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-accent p-4 rounded-full shadow-lg transform transition-all duration-300 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
        aria-label="Toggle social media links"
      >
        <FontAwesomeIcon icon={faShareAlt} className="text-white text-2xl" />
      </button>
      {/* Social Media Icons */}
      <div
        className={`mt-2 lg:mt-4 flex ${
          isOpen ? 'flex' : 'hidden'
        } lg:flex-col items-center lg:items-start space-x-4 lg:space-x-0 lg:space-y-4`}
      >
        <a
          href="https://www.instagram.com/cakesbyluna/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit our Instagram"
          className="bg-retroBlue p-3 rounded-full shadow-lg transform transition-all duration-300 hover:scale-110"
        >
          <FontAwesomeIcon icon={faInstagram} className="text-white text-2xl" />
        </a>
        <a
          href="https://www.tiktok.com/@cakesbyluna/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit our TikTok"
          className="bg-retroPink p-3 rounded-full shadow-lg transform transition-all duration-300 hover:scale-110"
        >
          <FontAwesomeIcon icon={faTiktok} className="text-white text-2xl" />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaSidebar;