// components/SignUpForm.js
import Card from './Card';

const SignUpForm = () => {
  return (
    <section id="contact" className="py-16 bg-retroPink">
      <div className="max-w-md mx-auto px-4">
        <Card className="bg-white text-center p-8 shadow-lg">
          <h2 className="text-3xl font-heading text-accent mb-6">Stay Updated</h2>
          <p className="font-body text-retroGray mb-8">
            Join our mailing list for the latest updates and special offers.
          </p>
          <form className="space-y-4">
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="w-full p-3 rounded-full text-retroGray bg-secondary border-none focus:outline-none focus:ring-2 focus:ring-accent"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-accent text-white py-3 px-6 rounded-full shadow-md transform transition duration-300 hover:-translate-y-1 hover:bg-retroBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
            >
              Sign Up Now
            </button>
          </form>
        </Card>
      </div>
    </section>
  );
};

export default SignUpForm;