// components/Footer.js
const Footer = () => {
  return (
    <footer className="bg-secondary py-8">
      <div className="max-w-6xl mx-auto px-4 text-center">
        <p className="text-retroGray font-body">
          © 2024 Cakes by Luna. All Rights Reserved.
        </p>
        <div className="mt-4 space-x-4">
          <a
            href="/privacy-policy"
            className="text-retroGray hover:text-accent transition-colors"
          >
            Privacy Policy
          </a>
          <span className="text-retroGray">|</span>
          <a
            href="/contact"
            className="text-retroGray hover:text-accent transition-colors"
          >
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;