// components/HeroSection.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
// components/HeroSection.js
import '../../styles/animations.css';

const HeroSection = () => {
  useEffect(() => {
    // Optional: Implement any JavaScript needed for animations
  }, []);

  return (
    <section
      id="home"
      className="relative flex items-center justify-center h-screen bg-primary overflow-hidden"
    >
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={require('../../assets/images/hero-background.jpeg')} // Replace with your actual image path
          alt="Artistic Cake Display"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 text-center px-4 max-w-2xl mx-auto">
        <h1 className="text-5xl md:text-7xl font-logo text-white mb-6 leading-tight animate-fade-in">
          Where Art Meets Cake
        </h1>
        <p className="text-xl md:text-2xl font-body text-gray-200 mb-8 animate-fade-in delay-200">
          Exquisite, personalized cakes with handcrafted decorations.
        </p>
        <button className="inline-flex items-center bg-accent text-white py-3 px-8 rounded-full shadow-xl transform transition-transform duration-300 hover:-translate-y-1 hover:bg-retroPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent animate-fade-in delay-400">
          Get Started
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </button>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white animate-bounce">
        <FontAwesomeIcon icon={faChevronDown} size="2x" />
      </div>
    </section>
  );
};

export default HeroSection;