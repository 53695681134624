// components/KeyOfferings.js
import { useState } from 'react';
import Card from './Card';

// Import images
import luxuryCakesImage from '../../assets/images/luxury-cakes.png';
import heavenlyStrawberryImage from '../../assets/images/heavenly-strawberry.png';
import redVelvetImage from '../../assets/images/red-velvet.png';
import cupcakesImage from '../../assets/images/cupcakes.png';
import vanillaBlissImage from '../../assets/images/vanilla-bliss.png';
import chocolateIndulgenceImage from '../../assets/images/chocolate-indulgence.png';
import macaronsImage from '../../assets/images/macarons.png';
import raspberryDelightImage from '../../assets/images/raspberry-delight.png';
import passionFruitDreamImage from '../../assets/images/passion-fruit-dream.png';
import edibleDecorationsImage from '../../assets/images/edible-decorations.png';
import sugarRosesImage from '../../assets/images/sugar-roses.png';
import chocolateFigurinesImage from '../../assets/images/chocolate-figurines.png';

const KeyOfferings = () => {
  const [selectedOffering, setSelectedOffering] = useState(null);

  const offerings = [
    {
      category: 'Luxury Cakes',
      description: 'Exquisite cakes crafted with the finest ingredients.',
      image: luxuryCakesImage,
      items: [
        {
          name: 'Heavenly Strawberry',
          description:
            'A decadent strawberry cake layered with fresh strawberries and cream, topped with a luscious strawberry glaze.',
          image: heavenlyStrawberryImage,
        },
        // Uncomment the following block if you have the red velvet image
        
        {
          name: 'Red Velvet',
          description:
            'A classic red velvet cake with a smooth cream cheese frosting, perfect for any special occasion.',
          image: redVelvetImage,
        },
        
      ],
    },
    {
      category: 'Cupcakes',
      description: 'Delightful cupcakes perfect for any occasion.',
      image: cupcakesImage,
      items: [
        {
          name: 'Vanilla Bliss',
          description:
            'Moist vanilla cupcakes topped with creamy vanilla buttercream frosting.',
          image: vanillaBlissImage,
        },
        {
          name: 'Chocolate Indulgence',
          description:
            'Rich chocolate cupcakes with a velvety chocolate ganache frosting.',
          image: chocolateIndulgenceImage,
        },
      ],
    },
    {
      category: 'Macarons',
      description: 'Elegant and colorful French macarons.',
      image: macaronsImage,
      items: [
        {
          name: 'Raspberry Delight',
          description: 'Macarons filled with a tangy raspberry buttercream.',
          image: raspberryDelightImage,
        },
        {
          name: 'Passion Fruit Dream',
          description: 'Exotic passion fruit macarons with a creamy filling.',
          image: passionFruitDreamImage,
        },
      ],
    },
    {
      category: 'Edible Cake Decorations',
      description: 'Handcrafted edible models and decorations for your cakes.',
      image: edibleDecorationsImage,
      items: [
        {
          name: 'Sugar Roses',
          description: 'Delicate roses crafted from sugar paste.',
          image: sugarRosesImage,
        },
        {
          name: 'Chocolate Figurines',
          description: 'Custom figurines made from modeling chocolate.',
          image: chocolateFigurinesImage,
        },
      ],
    },
  ];

  return (
    <section id="products" className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-center text-4xl font-heading text-accent mb-12">
          Our Key Offerings
        </h2>
        <div className="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          {offerings.map((offering, index) => (
            <Card key={index} className="overflow-hidden flex flex-col">
              <div className="relative group">
                <img
                  src={offering.image}
                  alt={offering.category}
                  className="object-cover w-full h-64 transform group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
                <h3 className="absolute bottom-4 left-4 text-2xl font-heading text-white">
                  {offering.category}
                </h3>
              </div>
              <div className="p-6 flex flex-col flex-grow">
                <p className="font-body text-retroGray mb-4 flex-grow">
                  {offering.description}
                </p>
                <button
                  onClick={() => setSelectedOffering(offering)}
                  className="mt-4 bg-accent text-white py-2 px-6 rounded-full shadow-lg transform transition duration-300 hover:-translate-y-1 hover:bg-retroPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
                >
                  Learn More
                </button>
              </div>
            </Card>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedOffering && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={() => setSelectedOffering(null)}
        >
          <div
            className="bg-white rounded-lg max-w-2xl w-full p-6 relative overflow-y-auto max-h-full"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={() => setSelectedOffering(null)}
              className="absolute top-4 right-4 text-retroGray hover:text-accent focus:outline-none"
              aria-label="Close"
            >
              &#x2715;
            </button>
            <h3 className="text-2xl font-heading text-accent mb-4">
              {selectedOffering.category}
            </h3>
            <p className="font-body text-retroGray mb-6">
              {selectedOffering.description}
            </p>
            <div className="space-y-6">
              {selectedOffering.items.map((item, idx) => (
                <div key={idx} className="flex items-start space-x-4">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-24 h-24 object-cover rounded-lg shadow-md"
                  />
                  <div>
                    <h4 className="font-heading text-xl text-retroBlue">
                      {item.name}
                    </h4>
                    <p className="font-body text-retroGray">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <button
              onClick={() => setSelectedOffering(null)}
              className="mt-6 bg-retroPink text-white py-2 px-6 rounded-full shadow-lg transform transition duration-300 hover:-translate-y-1 hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-retroPink"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default KeyOfferings;