// components/Testimonials.js
import { FaStar, FaQuoteLeft } from 'react-icons/fa';
import Card from './Card';

const Testimonials = () => {
  const testimonials = [
    {
      id: 1,
      customer_name: 'Emily R.',
      review_text:
        'The cake Andrea made for our wedding was absolutely stunning and delicious! We received so many compliments.',
      rating: 5,
    },
    {
      id: 2,
      customer_name: 'Michael T.',
      review_text:
        'Amazing craftsmanship! The custom decorations were a hit at my daughter\'s birthday party.',
      rating: 5,
    },
    {
      id: 3,
      customer_name: 'Sophia L.',
      review_text:
        'I was blown away by the attention to detail. The macarons were heavenly!',
      rating: 4,
    },
    // Add more testimonials as needed
  ];

  return (
    <section className="py-16 bg-primary">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-center text-4xl font-heading text-accent mb-12">
          What Our Customers Are Saying
        </h2>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.length > 0 ? (
            testimonials.map((testimonial) => (
              <Card
                key={testimonial.id}
                className="p-6 bg-white shadow-lg rounded-lg relative overflow-hidden"
              >
                {/* Decorative Quote Icon */}
                <FaQuoteLeft className="text-retroPink text-5xl absolute -top-4 -left-3 opacity-10" />
                {/* Testimonial Content */}
                <div className="flex flex-col h-full">
                  <p className="text-retroGray leading-relaxed flex-grow">
                    "{testimonial.review_text}"
                  </p>
                  <div className="mt-6">
                    <p className="font-bold text-retroBlue">
                      – {testimonial.customer_name}
                    </p>
                    <div className="flex mt-1">
                      {Array.from({ length: 5 }, (_, index) => (
                        <FaStar
                          key={index}
                          className={`${
                            index < testimonial.rating
                              ? 'text-yellow-500'
                              : 'text-gray-300'
                          }`}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <p className="text-retroGray mt-4">
              No testimonials available at the moment.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;