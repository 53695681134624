// components/AboutSection.js
import { useEffect } from 'react';

const AboutSection = () => {
  return (
    <section id="about" className="relative py-16 bg-secondary overflow-hidden">
      {/* Decorative Background Elements */}
      <div className="absolute inset-0">
        <img
          src={require('../../assets/images/about-background.jpeg')} // Replace with your background image path
          alt=""
          className="w-full h-full object-cover opacity-20"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-secondary via-white to-secondary opacity-90"></div>
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 flex flex-col md:flex-row items-center">
        {/* Image Section */}
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
          <div className="relative">
            {/* Decorative Circle Behind Image */}
            <div className="absolute -left-8 -top-8 w-80 h-80 rounded-full bg-retroPink opacity-30 transform rotate-12"></div>
            <img
              src={require('../../assets/images/andrea.jpg')} // Replace with your image path
              alt="Andrea"
              className="relative mx-auto rounded-full w-72 h-72 object-cover shadow-2xl border-4 border-white"
            />
          </div>
        </div>

        {/* Text Section */}
        <div className="md:w-1/2 text-center md:text-left md:pl-8">
          <h2 className="text-4xl md:text-5xl font-heading text-accent mb-6 animate-fade-in">
            Meet Andrea
          </h2>
          <p className="text-lg md:text-xl font-body text-retroGray leading-relaxed animate-fade-in delay-200">
            With over a decade of experience, Andrea crafts cakes that are as beautiful as they are delicious, blending artistry with flavor.
          </p>
          <p className="text-lg md:text-xl font-body text-retroGray leading-relaxed mt-4 animate-fade-in delay-400">
            Her passion for baking and eye for detail ensure that every creation is a masterpiece, tailored to your unique vision.
          </p>
          {/* Optional Call-to-Action Button */}
          <button className="mt-6 bg-accent text-white py-3 px-8 rounded-full shadow-lg transform transition duration-300 hover:-translate-y-1 hover:bg-retroBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent animate-fade-in delay-600">
            Explore Her Creations
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;