// App.js

import React from 'react';
import './App.css'; // Global styles if needed
import './pages/LandingPage.css'; // Custom CSS for LandingPage
import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is included
import LandingPage from './pages/LandingPage'; // Import the new consolidated LandingPage

function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
