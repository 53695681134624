// components/DIYSection.js
import { useState } from 'react';
import Card from './Card';

const DIYSection = () => {
  const [email, setEmail] = useState('');

  return (
    <section className="relative py-16 bg-gradient-to-r from-retroPink to-retroBlue overflow-hidden animate-gradient">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={require('../../assets/images/diy-background.jpeg')} // Replace with your actual image path
          alt="Cake Decorating Tools"
          className="w-full h-full object-cover opacity-30"
        />
      </div>

      {/* Content */}
      <div className="relative max-w-5xl mx-auto px-4">
        <Card className="bg-white bg-opacity-90 backdrop-filter backdrop-blur-md rounded-lg text-center p-8 shadow-2xl">
          <h2 className="text-4xl md:text-5xl font-heading text-accent mb-6">
            Unleash Your Inner Cake Artist
          </h2>
          <p className="md:text-lg font-body text-retroGray mb-8">
            Coming Soon: DIY Cake Kits and Cake Decoration Workshops.
          </p>
          {/* Subscription Form */}
          <form
            className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full sm:w-auto p-3 rounded-full text-retroGray bg-white border border-retroGray focus:outline-none focus:ring-2 focus:ring-accent"
              required
            />
            <button
              type="submit"
              className="bg-accent text-white py-3 px-8 rounded-full shadow-lg transform transition duration-300 hover:-translate-y-1 hover:bg-retroBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
            >
              Notify Me
            </button>
          </form>
        </Card>
      </div>
    </section>
  );
};

export default DIYSection;