// src/pages/LandingPage.js
import React, { useEffect } from 'react';
import Navbar from '../components/LandingPage/NavBar';
import HeroSection from '../components/LandingPage/HeroSection';
import DIYSection from '../components/LandingPage/DIYSection';
import KeyOfferings from '../components/LandingPage/KeyOfferings';
import AboutSection from '../components/LandingPage/AboutSection';
import Testimonials from '../components/LandingPage/Testimonials';
import SignUpForm from '../components/LandingPage/SignUpForm';
import Footer from '../components/LandingPage/Footer';
import SocialMediaSidebar from '../components/LandingPage/SocialMediaSidebar';

const LandingPage = () => {
  // Implement smooth scrolling behavior
  useEffect(() => {
    const handleAnchorClick = (e) => {
      if (e.target.matches('a[href^="#"]')) {
        e.preventDefault();
        const targetId = e.target.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 80, // Adjust for fixed navbar height
            behavior: 'smooth',
          });
        }
      }
    };

    document.addEventListener('click', handleAnchorClick);

    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, []);

  return (
    <div className="bg-primary font-body text-retroGray">
      <Navbar />
      <SocialMediaSidebar />
      <main>
        <HeroSection />
        <DIYSection />
        <KeyOfferings />
        <AboutSection />
        <Testimonials />
        <SignUpForm />
      </main>
      <Footer />
    </div>
  );
};

export default LandingPage;