// components/Navbar.js
import { useState, useEffect } from 'react';
import Logo from '../../assets/images/logo.png'; // Adjust the path as needed

const Navbar = () => {
  const [activeSection, setActiveSection] = useState('home');
  const [isOpen, setIsOpen] = useState(false); // State for mobile menu

  useEffect(() => {
    const sections = ['home', 'products', 'about', 'contact'];
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (
          element &&
          element.offsetTop <= scrollPosition &&
          element.offsetTop + element.offsetHeight > scrollPosition
        ) {
          setActiveSection(section);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
      <div className="max-w-6xl mx-auto flex justify-between items-center py-2 md:py-3 px-4">
        {/* Logo */}
        <a href="#home" className="flex items-center">
          <img
            src={Logo}
            alt="Cakes by Luna Logo"
            className="h-12 md:h-16 w-auto" // Reduced height for logo
          />
        </a>
        {/* Desktop Navigation Links */}
        <div className="hidden md:flex space-x-6 text-retroGray font-heading">
          <a
            href="#home"
            className={`hover:text-accent transition-colors ${
              activeSection === 'home' ? 'text-accent' : ''
            }`}
          >
            Home
          </a>
          <a
            href="#products"
            className={`hover:text-accent transition-colors ${
              activeSection === 'products' ? 'text-accent' : ''
            }`}
          >
            Products
          </a>
          <a
            href="#about"
            className={`hover:text-accent transition-colors ${
              activeSection === 'about' ? 'text-accent' : ''
            }`}
          >
            About
          </a>
          <a
            href="#contact"
            className={`hover:text-accent transition-colors ${
              activeSection === 'contact' ? 'text-accent' : ''
            }`}
          >
            Contact
          </a>
        </div>
        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle navigation menu"
          >
            {/* Using SVG icons for simplicity */}
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-retroGray"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* Close icon */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-retroGray"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* Menu icon */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 8h16M4 16h16"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      {/* Mobile Navigation Links */}
      {isOpen && (
        <div className="md:hidden bg-white shadow-md">
          <div className="flex flex-col space-y-2 py-2 text-center">
            <a
              href="#home"
              className="text-retroGray font-heading hover:text-accent"
              onClick={() => setIsOpen(false)}
            >
              Home
            </a>
            <a
              href="#products"
              className="text-retroGray font-heading hover:text-accent"
              onClick={() => setIsOpen(false)}
            >
              Products
            </a>
            <a
              href="#about"
              className="text-retroGray font-heading hover:text-accent"
              onClick={() => setIsOpen(false)}
            >
              About
            </a>
            <a
              href="#contact"
              className="text-retroGray font-heading hover:text-accent"
              onClick={() => setIsOpen(false)}
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;